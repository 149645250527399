<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Vehículos')}} </h3>
		</div>
		<div class="p-col-12 p-md-5" v-if="!mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h5>Nº {{$t('Póliza')}}</h5>
					</template>
					<template #content>
						<div class="p-align-center vertical-container">
							INI-P-VEH-<InputText v-model="poliza" style="width: 120px;" type="number" :placeholder="$t('Número')" min="0" @keyup.enter="Buscar"/>-RX
						</div>					
					</template>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Siguiente')" icon="pi pi-arrow-right" class="p-button-secondary" @click="Buscar"/>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Policy :datos="seleccion" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-6" v-if="mostrar">
			<Basic :datos="seleccion" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-6" v-if="mostrar">
			<Information :datos="seleccion" :detall="seleccion.vehiculo"/>
		</div>

		<div class="p-col-12 p-grid" v-for="(item, index) in $store.state.claim.benef"  :key="index" >
			<div class="p-col-11" v-if="mostrar">
				<Beneficiary :edit="true" :tipo_veh="tipos" :datos="item"/>
			</div>
			<div class="p-col-1" v-if="mostrar">
				<Button :disabled ="$store.state.loading" icon="pi pi-plus" class="p-button-rounded p-button-secondary" @click="AgregBenef" v-if="index==0"/>
				<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DelBenef(item)" v-if="index>0"/>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:  
							<strong :style="'color:'+estado.color" v-if="i18n.locale() == 'es'">
								{{estado.nombre}}
							</strong>
							<strong :style="'color:'+estado.color" v-if="i18n.locale() == 'en'">
								{{estado.name}}
							</strong>
						</h4>
					</template>
					<template #content>					
						<div class="p-fluid p-formgrid p-grid">						
							<div class="p-field p-col-6">
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-field p-col-12">
										<Checkbox v-model="informacion.lesiones" :binary="true" />
										{{$t('Se reporta lesiones personales?')}}
									</div>
									<div class="p-field p-col-12">
										<Checkbox v-model="informacion.reemplazo" :binary="true" />
										{{$t('Se requiero Vehículo de reemplazo?')}}
									</div>
									<div class="p-field p-col-12">
										<Checkbox v-model="informacion.recuperacion" :binary="true" />
										{{$t('Aplica recuperación?')}}
									</div>
								</div>
							</div>
							<div class="p-field p-col-6">
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-field p-col-7">
										<label>{{$t('Fecha en que ocurrió el incidente')}}</label>
										<InputMask v-model="informacion.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" style="width:250px;"/>
										<small v-show="validationErrors.fecha" class="p-error">{{$t('required')}}</small>
									</div>
									<div class="p-field p-col-7">
										<label>{{$t('Monto de Reserva')}}: </label>
										<InputNumber id="horizontal" v-model="informacion.estimacion" showButtons buttonLayout="horizontal" 
											decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
											decrementButtonIcon="pi pi-minus" :min="informacion.reserva" mode="currency" :currency="$store.state.currency" style="max-width:260px;"/>
									</div>
									<div class="p-field p-col-7">
										<label>{{$t('Forence')}}</label>
										<Dropdown id="state" v-model="informacion.forence" :options="forences" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
										<small v-show="validationErrors.forence" class="p-error">{{$t('required')}}</small>
									</div>
									<div class="p-field p-col-4" v-if="informacion.forence.code>0">
										<Checkbox v-model="informacion.notificar" :binary="true" />
										{{$t('Notificar al forence')}}
									</div>
								</div>
							</div>
						</div>
					</template>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" @click="Guardar()" />
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="Mostrar"/>
						<Dropdown class="p-mr-2 p-mb-2" id="state" v-model="estado" :options="estados" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;"></Dropdown>
						<Dropdown class="p-mr-2 p-mb-2" id="state" v-model="estado2" :options="estados2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;" v-if="estado.code==2"></Dropdown>
					</template>
				</Card>
			</div>
		</div>

	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim2');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Policy: defineAsyncComponent(() => import('../comp_claim/Policy.vue')),
			Basic: defineAsyncComponent(() => import('../comp_claim/Basic.vue')),
			Information: defineAsyncComponent(() => import('../comp_claim/Information.vue')),
			Beneficiary: defineAsyncComponent(() => import('../comp_claim/Beneficiary.vue')),
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				polizas: [],
				seleccion: {},
				mostrar: false,
				forences: [],
				tipos: [],
				estados: [],
				estados2: [],
				validationErrors: {},
				informacion: {
					lesiones: false,
					reemplazo: false,
					recuperacion: false,
					fecha: null,
					reserva: 0,
					estimacion: 0,
					forence: {nombre: 'En Evaluación', name: 'In Evaluation', code: 0},
					notificar: false,
				},
				estado: null,
				estado2: null,
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.polizas = [];
				this.$store.state.claim.benef = [];
				this.mostrar = false;
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					if (result.consult.forences){
						this.forences = result.consult.forences;
					}
					if (result.consult.tipos){
						this.tipos = result.consult.tipos;
					}           
					if (result.consult.estados){
						this.estados = result.consult.estados;
						this.estados2 = result.consult.estados2;
						this.estado = this.estados[1];
						this.estado2 = this.estados2[0];
					}
					this.informacion.reserva =  result.consult.reserva;       
					this.informacion.estimacion =  result.consult.reserva;
				}); 
			},
			Buscar() {
				this.$store.state.claim.benef = [];
				this.seleccion = {};
				Consulta.Procesar('BuscarReg',{
					poliza: this.poliza,
				}).then(response => {
					//this.$store.state.error = response;
					if(response.result){
						this.estado = this.estados[1];
						this.seleccion = response.result;
						this.New();
						this.mostrar = true;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error')+' '+this.$t('Número Póliza'), 
							life: 3000
						});
					}
				});
			},
			Guardar() {
				if (this.validateForm()) {
					this.informacion.fecha = Consulta.fdate(this.informacion.fecha);      
					Consulta.Procesar('INS',{
						claim: this.seleccion,
						informacion: this.informacion,
						beneficiarios: this.$store.state.claim.benef,
						estado: this.estado,
						estado2: this.estado2
					}).then(response => {
						//this.$store.state.error = response;
						if (response.result) {
							this.$toast.add({
								severity:'success',
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.$store.commit('Loading');
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error'), 
								life: 3000
							});
						}
					});
				}
			},
			validateForm() {
				if (this.informacion.fecha.length!=10)
				this.validationErrors['fecha'] = true;
				else
				delete this.validationErrors['fecha'];
				if (this.informacion.reserva==null)
				this.validationErrors['reserva'] = true;
				else
				delete this.validationErrors['reserva'];
				if (this.informacion.forence==null)
				this.validationErrors['forence'] = true;
				else
				delete this.validationErrors['forence'];

				return !Object.keys(this.validationErrors).length;
			},
			New(){
				this.$store.state.claim.benef.push({
					code: null,
					nombre: null,
					correo: null,
					celular: null,
					tlflocal: null,
					tipo: null,
					marca: null,
					modelo: null,
					year: null,
					placa: null,
				});
			},
			AgregBenef() {
				this.$store.state.claim.benef.push({
					code: null,
					nombre: null,
					correo: null,
					celular: null,
					tlflocal: null,
					tipo: null,
					marca: null,
					modelo: null,
					year: null,
					placa: null,
				});
			},
			DelBenef(selec) {
				if(this.$store.state.claim.benef.length>1){
					this.$store.state.claim.benef = this.$store.state.claim.benef.filter(val => val !== selec);
				}
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
